<template>
  <EditDialog width="45%" :isShow.sync="isShow" :isReturn="true" @closeFn="onOutWorkDialogClose">
    <div slot="title" class="title">外勤报告</div>
    <div slot="content" class="content" style="min-height: 480px; padding: 0 20px">
      <el-form style="width: 100%" :model="outWorkInfo" label-width="80px" label-position="left">
        <el-form-item>
          <template slot="label"> 证明人 </template>
          <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            附件
            <el-tooltip class="item" effect="light" placement="top">
              <div slot="content" style="font-size: 18px; color: #000">
                工作派遣、聊天记录、通知函。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <div
            v-for="(n, index) in outWorkInfo.manhourFileList"
            :key="index"
            style="margin-top: 5px"
            class="file-list"
          >
            <a
              class="fj-text file-name"
              target="_blank"
              style="text-decoration: revert; color: #409eff; padding-right: 8px"
              :href="`${filepath}${n.filePath}`"
            >
              {{ n.fileName }}
            </a>
          </div>
        </el-form-item>
        <el-form-item label="外勤说明" prop="content" style="width: 100%">
          <el-input
            type="textarea"
            v-model="outWorkInfo.content"
            readonly
            :autosize="{ minRows: 4, maxRows: 9999 }"
            placeholder="请输入外勤说明"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button type="info" plain @click="onOutWorkDialogClose">关闭</el-button>
    </div>
  </EditDialog>
</template>

<script>
export default {
  name: 'OutWorkDetail',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    // 数据源
    outWorkInfo: {
      type: Object,
      default: {},
    },

    //显示隐藏
    isOutWorkDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
    }
  },
  watch: {
    isShow: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('update:isOutWorkDialogShow', this.isShow)
        }
      },
    },
  },
  created() {
    this.isShow = this.isOutWorkDialogShow
  },
  methods: {
    onOutWorkDialogClose() {
      this.isShow = false
    },
  },
}
</script>

<style scoped lang="scss">
.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
